import common from '@/common/mixins/common.js'
import page from '@/common/mixins/page.js'
import {
	mapActions
} from 'vuex'
import Moment from 'moment'
export default {
	inject: ['adminLayout'],
	mixins: [common, page],
	data() {
		return {
			queryForm: {
				domainNames: [],
				date: [
					Moment().startOf('day').add(-1, 'day').format('YYYY-MM-DD'),
					Moment().startOf('day').add(-1, 'day').format('YYYY-MM-DD')
				]
			},
			tableData: [],
			q: {
				dateFrom: Moment().startOf('day').add(-1, 'day').format('YYYY-MM-DD'),
				dateTo: Moment().startOf('day').add(-1, 'day').format('YYYY-MM-DD')
			},
			namespace: '',
			fetchOnCreated: false
		}
	},
	created() {
		const {
			dateFrom,
			dateTo,
			domainNames
		} = this.$route.query
		if (dateFrom && dateTo && /\d{4}-\d{2}-\d{2}/.test(dateFrom) && /\d{4}-\d{2}-\d{2}/.test(dateTo)) {
			this.queryForm.date = [dateFrom, dateTo]
			this.q.dateFrom = dateFrom
			this.q.dateTo = dateTo
		}
		if (domainNames !== undefined && domainNames.match(/\b[a-zA-Z0-9.-]+\.[a-z]+\b/g) !== null) {
			this.queryForm.domainNames = domainNames.match(/\b[a-zA-Z0-9.-]+\.[a-z]+\b/g)
		}
		this.searchEvent(false)
	},
	methods: {
		buildQueryForm(queryForm) {
			let query = {};
			for (let key in queryForm) {
				if (queryForm.hasOwnProperty(key) && (queryForm[key].length > 0 || typeof(queryForm[key]) ===
						'boolean')) {
					if (key === 'date') {
						const [dateFrom, dateTo] = queryForm[key]
						query['dateFrom'] = dateFrom
						query['dateTo'] = dateTo
					} else if (key === 'domainNames') {
						query['domainNames'] = queryForm['domainNames'].reduce((lhs, rhs) => {
							if (rhs.match(/\b[a-zA-Z0-9.-]+\.[a-z]+\b/g) !== null) {
								lhs.push(rhs)
							}
							return lhs
						}, [])
					} else {
						query[key] = queryForm[key]
					}
				}
			}
			return query;
		},
		buildSearch(query) {
			let search = []
			for (let key in query) {
				if (Array.isArray(query[key])) {
					search.push(`${key}=${query[key].join(',')}`)
				} else {
					search.push(`${key}=${query[key]}`)
				}
			}
			return search.join('&')
		},
		searchEvent(showErr = true) {
			this.q = this.buildQueryForm(this.queryForm)
			const search = this.buildSearch(this.q)
			history.replaceState(this.q, '', `${window.location.pathname}?${search}`)
			this.getList()
		},
		handleRawData(content) {
			this.tableData = this.handleHourData(content)
			this.doLayout()
		},
		handleHourData(content) {
			return content.reduce((lhs, rhs) => {
				const _rawData = {
					costDate: rhs.costDate,
					domainName: rhs.domainName,
				}
				const data_conversions = {
					total: 0
				};
				const data_epc = {
					total: 0
				}
				let sum_conversions = 0;
				let sum_revenues = 0;
				// 遍历数据填充字典
				(rhs.datas || []).forEach(item => {
					const hour = item.hour + 1 + ''
					const conversions = Number(item.conversions)
					const revenues = Number(item.revenues)
					data_conversions[hour] = conversions
					data_conversions.total += conversions
					data_epc[hour] = Number((conversions === 0 ? 0 : revenues / conversions).toFixed(2))
					sum_conversions += conversions
					sum_revenues += revenues
				})
				data_epc.total = Number((sum_conversions === 0 ? 0 : sum_revenues / sum_conversions).toFixed(2))
			
				const firstRow = {
					..._rawData,
					dataProp: 'Converison',
					...data_conversions
				}
				const secondRow = {
					..._rawData,
					dataProp: 'Epc',
					...data_epc
				}
				lhs.push(firstRow)
				lhs.push(secondRow)
				return lhs
			}, [])
		},
		doLayout() {},
		async getListUrl() {
			if (this.namespace === '') return
			const trigger = this.trigger
			return this.$store.dispatch(`${this.namespace}/fetchJSCXDomainHour`, {
				params: {
					...this.q
				},
				trigger
			})
		},
		downloadTask() {
			const q = this.buildQueryForm(this.queryForm)
			this.$store.commit('download-manager/addTask', {
				action: `${this.namespace}/fetchJSCXDomainHour`,
				payload: {
					...q
				},
				title: `JSCX_DomainHourReport_(${q.dateFrom}~${q.dateTo})`,
				dispatch: this.$store.dispatch,
				requestHandler: (payload, pagable) => {
					return {
						params: payload
					}
				},
				responseHandler: (content) => {
					return {
						content: this.handleHourData(content),
						totalPages: 1
					}
				}
			})
		}
	}
}