<template>
	<el-card>
		<advanced-search-bar>
			<template>
				<form-item label="选择日期:" style="width:300px;">
					<el-date-picker v-model="queryForm.date" type="daterange" start-placeholder="开始日期"
						end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width:340px;" size="mini"
						:clearable="false" :picker-options="pickerOptions">
					</el-date-picker>
				</form-item>
				<domains-input :domainNames.sync="queryForm.domainNames"></domains-input>
				<form-item>
					<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
					<el-button type="primary" size="mini" @click="downloadTask">下 载</el-button>
					<!-- <el-button type="primary" @click="showFilter" size="mini">过 滤</el-button> -->
				</form-item>
			</template>
			<template #advanced v-if="false">
			</template>
		</advanced-search-bar>
		<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);">
		</div>
		<el-table height="auto" ref="multipleTable" :data="tableData" size="mini" border
			:span-method="objectSpanMethod" class="jscx-domain-hour" :cell-class-name="cellClassName">
			<el-table-column label="Date" min-width="100" align="center" fixed>
				<template slot-scope="scope">
					{{scope.row.costDate | formatDate('YYYY-MM-DD', false)}}
				</template>
			</el-table-column>
			<el-table-column label="Domain Name" prop="domainName" min-width="120" align="center" fixed />
			<el-table-column label="Data" prop="dataProp" min-width="100" align="center" fixed />
			<el-table-column v-for="i in 24" :label="`${i}`" :prop="`${i}`" min-width="80" align="center" :formatter="cellFormatter" />
			<el-table-column label="Total" prop="total" min-width="80" align="center" fixed="right" :formatter="cellFormatter">
			</el-table-column>
		</el-table>
	</el-card>
</template>

<script>
	import Moment from 'moment'
	import config from './minixs/domain-hour.js'
	import DomainsInput from '@/components/jscx/domains-input.vue'
	export default {
		components: {
			DomainsInput
		},
		mixins: [config],
		data() {
			return {
				namespace: 'jscx',
				pickerOptions: {
					disabledDate(time) {
						const today = new Date();
						return time.getTime() > today; // 禁用不在范围内的日期
					}
				}
			}
		},
		methods: {
			objectSpanMethod({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				if (columnIndex < 2) {
					if (rowIndex % 2 === 0) {
						return {
							rowspan: 2,
							colspan: 1
						};
					} else {
						return {
							rowspan: 0,
							colspan: 0
						};
					}
				}
			},
			cellFormatter(row, column, cellValue, index) {
				if (column.property === 'total') {
					return index % 2 === 0 ? cellValue : cellValue.toFixed(2)
				} else {
					if (row.hasOwnProperty(column.property)) {
						return index % 2 === 0 ? cellValue : cellValue.toFixed(2)
					}
					return index % 2 === 0 ? 0 : '0.00'
				}
			},
			cellClassName({row, column, rowIndex, columnIndex}) {
				if (rowIndex % 2 === 1) {
					if (/^\d+$/.test(column.property)) {
						const hourEpc = row.hasOwnProperty(column.property) ? row[column.property] : 0
						const totalEpc = row.total
						
						return hourEpc < (0.6 * totalEpc) ? 'lose-row' : ''
					}
				}
			}
		}
	}
</script>

<style>
	.jscx-domain-hour.el-table .el-table__body tr:hover>td.el-table__cell {
		background-color: white;
	}
	.jscx-domain-hour.el-table .el-table__row.hover-row {
		background-color: white;
	}
	.jscx-domain-hour.el-table .el-table__body tr.hover-row > td.el-table__cell {
		background-color: white;
	}
	.jscx-domain-hour.el-table .lose-row {
		background: #FCEDEB;
	}
</style>